import ScrollTop from '@components/ScrollTop/ScrollTop';
import React, { useEffect, useState } from 'react';
import { useLayoutStore } from 'src/stores/layout/useLayout.store';
import { BottomFixedButtonsContainer } from './BottomFixedButtons.styles';
import { createPortal } from 'react-dom';
import StoreToggleButton from './StoreToggleButton';
import Snackbar from '../Snackbar/Snackbar';
import { shallow } from 'zustand/shallow';
import useResponsiveDevice from '@hooks/useResponsiveDevice';
import { useTranslation } from 'next-i18next';
import { LANGUAGE_CODE } from 'src/constants';

export default function BottomFixedButtons() {
  const { width } = useResponsiveDevice();
  const { i18n } = useTranslation();
  const [documentMounted, setDocumentMounted] = useState(false);
  const [viewportHeightDiff, setViewportHeightDiff] = useState<number>(0);
  const [isShowTopButton, snackbarMessage, fixedButtonsBottom] = useLayoutStore((state) => [state.isShowTopButton, state.snackbarMessage, state.fixedButtonsBottom], shallow);
  const bottom = viewportHeightDiff ? viewportHeightDiff : fixedButtonsBottom;

  useEffect(() => {
    setDocumentMounted(true);
    if (typeof window === 'undefined') return;

    const getViewportHeightDiff = () => {
      return window.innerHeight - window.visualViewport.height;
    };

    const resizeHandler = () => {
      const visualOffset = window.visualViewport.offsetTop;
      const heightDiff = getViewportHeightDiff();

      if (Math.abs(heightDiff) < 100) {
        setViewportHeightDiff(0);
        return;
      }

      setViewportHeightDiff(heightDiff + 30 - visualOffset);
    };
    resizeHandler();

    const scrollHandler = () => {
      if (getViewportHeightDiff() > 0) resizeHandler();
    };

    window.visualViewport.addEventListener('resize', resizeHandler);
    window.visualViewport.addEventListener('scroll', scrollHandler);
    return () => {
      window.visualViewport.removeEventListener('resize', resizeHandler);
      window.visualViewport.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  if (!documentMounted) return <div></div>;

  return createPortal(
    <>
      <BottomFixedButtonsContainer bottom={bottom}>
        {isShowTopButton && <ScrollTop />}
        {width <= 768 && [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE) && <StoreToggleButton />}
      </BottomFixedButtonsContainer>
      {snackbarMessage && <Snackbar bottom={bottom} />}
    </>,
    document.getElementById('__next'),
  );
}

// @mui
import { useTheme } from '@mui/material/styles';
import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { waitUntilExist } from '@util/promiseUtil';

/* eslint-disable @typescript-eslint/no-empty-function */
export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export interface IDeviceState {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
}
const INITIAL_DEVICE_STATE: IDeviceState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  width: typeof window === 'undefined' ? 0 : window.innerWidth,
  height: typeof window === 'undefined' ? 0 : window.innerHeight,
};

export default function useResponsiveDevice(debounceTime = 100, onResizeEvent: any = ()=>{}) {
  const theme = useTheme();
  const [result, setResult] = useState<IDeviceState>(INITIAL_DEVICE_STATE);
  const sizes = theme.breakpoints.values;

  useBrowserLayoutEffect(() => {
    const resize = (e?: any) => {
      e && onResizeEvent && onResizeEvent(e);
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      const isMobile = innerWidth <= sizes.sm;
      const isTablet = innerWidth > sizes.sm && innerWidth <= sizes.md;
      const isDesktop = innerWidth > sizes.md;
      setResult({ isMobile, isTablet, isDesktop, width: innerWidth, height: innerHeight });
    };

    resize();

    function initializing(times = 0) {

      const content = document.querySelector('#__next > div.header + div') as HTMLDivElement;
      resize();
      if (window.innerWidth < 900 && content?.style) {
        content.style.display = 'none';
      }
      setTimeout(() => {
        if (window.innerWidth < 900 && content?.style) {
          content.style.display = '';
        }
        if (times < 6) initializing(times + 1);
      }, 50);
    }

    debounceTime === 0 && setTimeout(()=> initializing(0), 100);

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return result;
}

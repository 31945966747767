import styled, { keyframes } from 'styled-components';

const scaleEaseOutElastic = keyframes`
	0% {
		transform: scale(1);
	}

	16% {
		transform: scale(-0.32);
	}

	28% {
		transform: scale(0.13);
	}

	44% {
		transform: scale(-0.05);
	}

	59% {
		transform: scale(0.02);
	}

	73% {
		transform: scale(-0.01);
	}

	88% {
		transform: scale(0);
	}

	100% {
		transform: scale(0);
	}
`;

export const BottomFixedButtonsContainer = styled.div<{ bottom: number }>`
  position: sticky;
  bottom: ${(props) => props.bottom || 35}px;
  padding: 0px 35px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: bottom 0.2s ease-in;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 0px 12px;
    bottom: ${(props) => (props.bottom || 35) - 19}px;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import { StoreToggleButtonContainer, StoreToggleButtonItem } from './StoreToggleButton.styles';
import { CustomText } from '@components/ui';
import { useTranslation } from 'next-i18next';
import useSettings from '@hooks/useSettings';
import { LANGUAGE_CODE, PAGE_TYPE } from 'src/constants';
import { useRouter } from 'next/router';
import { useLayoutStore } from 'src/stores/layout/useLayout.store';

export default function StoreToggleButton() {
  const { themeMode } = useSettings();
  const { t, i18n } = useTranslation();
  const toggleButtonRef = useRef(null);
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<string>(themeMode);
  const setSnackbarMessage = useLayoutStore((state) => state.setSnackbarMessage);

  const handleButtonContainerClick = () => {
    if (isOpen) return;
    setIsOpen(true);
  };

  const handleButtonItemClick = (store: string) => {
    if (!isOpen) return;

    setMode(store);
    setIsOpen(false);

    // [todo]: 번역 문구 적용해야 함.
    if (isOpen && mode !== store) router.push(`/${store}`).then(() => setSnackbarMessage(t(`mode:move-to.${store}`)));
  };

  const handleOutSideClick = (event) => {
    const isClose = !toggleButtonRef.current || !toggleButtonRef.current.contains(event.target);
    if (isClose) setIsOpen(false);
  };

  const handleScroll = () => {
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutSideClick, true);
    return () => {
      document.removeEventListener('click', handleOutSideClick, true);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  return (
    <StoreToggleButtonContainer isOpen={isOpen} onClick={handleButtonContainerClick} ref={toggleButtonRef}>
      {[PAGE_TYPE.GAME, PAGE_TYPE.TOON]
        .filter((name) => isOpen || (!isOpen && name === mode))
        .map((name, i) => {
          const isActive = mode === name;
          return (
            <StoreToggleButtonItem
              key={i}
              isActive={isActive}
              onClick={() => {
                handleButtonItemClick(name);
              }}
            >
              <CustomText size={i18n.language === LANGUAGE_CODE.KO ? 14 : 10} weight={700} lineHeight="16px" color={isActive ? '#313135' : '#88888a'}>
                {t(`mode:${name}`)}
              </CustomText>
            </StoreToggleButtonItem>
          );
        })}
    </StoreToggleButtonContainer>
  );
}

import { Breakpoint, Breakpoints } from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsOfMui } from '@components/settings/ThemeMui';
import breakpointsOfMinimal from '../theme/minimal/breakpoints';

type TBreakpointArea = keyof Omit<Breakpoints, 'keys' | 'values' | 'unit'>;

export const getColor = (colorPathOrColor: string, density?: number|string) => {
  try {
    const colorPath = density ? [colorPathOrColor, density] : colorPathOrColor.split('/').slice(-2);
    return (props) => {
      try {
        return props.theme.palette[colorPath[0]][colorPath[1]];
      } catch (e) {
        return '#313135';
      }
    };
  } catch (e) {
    return '#313135';
  }
};


/**
 * only support up and down
 * @param breakpointValues
 * @param area
 * @param key
 */
const breakpointToMedia = (breakpointValues: Record<string, number>, area: TBreakpointArea, key: Breakpoint)=> {
  if (typeof breakpointValues[key] !== 'number' || !['up', 'down'].includes(area)) return '@media not screen and (min-width:9999999999px)';
  const value = breakpointValues[key];
  if (area === 'up') return `@media (min-width:${value}px)`;
  else if (area === 'down') return `@media (max-width:${value - 0.05}px)`;
};

export const getBreakpointedMedia = (area: TBreakpointArea, key: Breakpoint, useDefined?: 'mui' | 'minimal') => {
  if (useDefined === 'mui') return breakpointToMedia(breakpointsOfMui.values, area, key);
  if (useDefined === 'minimal') return breakpointToMedia(breakpointsOfMinimal.values, area, key);
  return (props) => (props.theme.breakpoints)[area](key);
};

import React from 'react';
import NextHead from 'next/head';
import { useTranslation } from 'next-i18next';
import { width } from '@mui/system';
import { normalizeSrc } from '@components/common/ResponsiveSlideBanner/ResponsiveSlideBanner.constants';
interface propType {
  isMobileView?: boolean;
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImageOriginal?: boolean;
  ogImageUrl?: string;
  ogTwitterTitle?: string;
  ogTwitterCardSize?: string;
}
// 공용적으로 사용되는 head 태그 들에 대하여 정의되어 있는 컴포넌트입니다.
export const Head: React.FC<propType> = ({
  isMobileView = false,
  title = '',
  description = '',
  ogImageOriginal = false,
  ogImageUrl = '',
  ogUrl = '',
  ogTitle = '',
  ogTwitterTitle = '',
  ogDescription = '',
  ogTwitterCardSize = '',
}) => {
  // 번역 도구
  const { i18n } = useTranslation();

  const headTitle = title
    ? title
    : (function () {
      if (i18n.language === 'ko') return 'ACON - 창작을 위한 디지털 에셋 스토어';
      else if (i18n.language === 'zh') return '3D模型背景平台 - ACON3D';

      return 'ACON - Digital Asset Store for All Creators';
    })();
  const keywords = (function () {
    if (i18n.language === 'ko') return '클립스튜디오, 프로크리에이트, 스케치업, 브러쉬, 오토액션, 3D배경, 웹툰 배경, 데포르메, 3D인체, 웹툰 소스, 3D 모델';
    else if (i18n.language === 'zh') return 'webtoon背景，3d設計，3d模型，動畫，設計，遊戲，建築, 建模, webtoon background, 3d design, 3d model, 3d, vr, ar, animation, design, sketchup, 3dmax, game, architechture webtoon, webcomics, modeling';
    else if (i18n.language === 'ja') return 'アニメ, アニメーション, 漫画, ar, vr, 設計, 建築, 3d, 3d 設計, sketchup, 3dmax, game, ゲーム,モデリング, 3d モデル, モデル, 背景, 漫画背景';

    return 'Clip Studio, Procreate, SketchUp, Brush, Auto Action, 3D Background, Webtoon Background, Deforme, 3D Body, Webtoon Source, 3D Model';
  })();
  const headDescription = description
    ? description
    : (function () {
      if (i18n.language === 'ko') return '디지털 에셋 스토어. 드로잉을 위한 브러쉬부터 3D 인체모델, 배경, 포즈집까지 창작에 필요한 모든 에셋을 자유롭게 사용하세요.';
      else if (i18n.language === 'zh') return '韩国最大的3D模型漫画背景以及素材平台！ 在ACON3D扩展你的创作世界吧！';
      else if (i18n.language === 'ja') return '3Dデザインオープンプラットフォーム。 ウェブトゥーン、ゲーム、アニメ、VRなど創作に必要な3Dモデルをご自由にお使いください。';

      return 'Digital Asset Store. Use brushes, 3D backgrounds & animated deformes for all creative fields!';
    })();

  const headOgTitle = ogTitle ? ogTitle : headTitle;
  const headOgDescription = ogDescription ? ogDescription : headDescription;
  const headOgUrl = ogUrl ? ogUrl : 'www.acon3d.com';
  const headOgImageUrl = ogImageOriginal
    ? ogImageUrl
    : ogImageUrl
      ? `${process.env.NEXT_PUBLIC_IMAGEOPTIMIZER}/?image=${ogImageUrl}&width=1200&quality=95`
      : 'https://www.acon3d.com/data/common/snsRepresentImage.png';
  const headOgTwitterCardSize = ogTwitterCardSize ? ogTwitterCardSize : 'summary';

  return (
    <>
      <NextHead>
        <title>{headTitle}</title>

        {/* 인코딩 및 import file */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="icon" href="/icons/favicon.png" type="image/x-icon" />
        <meta name="viewport" content={'width=device-width'} />
        <meta name="author" content="ACON3D" />

        {/* 다국어 처리 */}
        <link className="default" rel="canonical" href="https://www.acon3d.com" />
        <link className="ko" rel="alternate" hrefLang="ko-KR" href="https://www.acon3d.com/ko" />
        <link className="en" rel="alternate" hrefLang="en-US" href="https://www.acon3d.com/en" />
        <link className="jp" rel="alternate" hrefLang="ja-JP" href="https://www.acon3d.com/ja" />
        <link className="cn" rel="alternate" hrefLang="zh-CN" href="https://www.acon3d.com/zh" />

        <meta property="og:site_name" content="ACON" />

        {/* open graph */}
        <meta property="title" content={headTitle} />
        <meta property="description" content={headDescription} />
        <meta property="keywords" content={keywords} />
        <meta property="og:title" content={headOgTitle} />
        <meta property="og:description" content={headOgDescription} />
        <meta property="og:url" content={headOgUrl} />
        <meta property="og:image" content={headOgImageUrl} />

        {/* twitter 용 */}
        <meta name="twitter:title" content={ogTwitterTitle ? ogTwitterTitle : headOgTitle} />
        <meta name="twitter:description" content={headOgDescription} />
        <meta name="twitter:url" content={headOgUrl} />
        <meta name="twitter:image" content={headOgImageUrl} />
        <meta name="twitter:card" content={headOgTwitterCardSize} />
      </NextHead>
    </>
  );
};

import { getBreakpointedMedia } from '@util/themeUtil';
import { Letter } from 'carpenstreet-designsystem';
import styled from 'styled-components';

export const SnackbarContainer = styled.div<{ bottom: number }>`
  background-color: #313135;
  width: auto;
  padding: 6px 16px;
  position: fixed;
  bottom: ${(props) => props.bottom || 60}px;
  z-index: 103;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    left: 35px;
    bottom: ${(props) => props.bottom || 35}px;
    width: calc(100% - 70px);
    transform: translateX(0);
    position: sticky;
  }

  ${getBreakpointedMedia('down', 'sm')} {
    width: calc(100% - 24px);
    left: 12px;
    bottom: ${(props) => (props.bottom || 35) - 19}px;
  }
`;

export const SnackbarMessageText = styled(Letter)`
  padding: 8px 0px;
`;

import React, { useEffect } from 'react';
import { SnackbarContainer, SnackbarMessageText } from './Snackbar.styles';
import { useLayoutStore } from 'src/stores/layout/useLayout.store';
import { shallow } from 'zustand/shallow';
import CloseIcon from 'src/assets/icons/snackbar/CloseIcon';
import { IconButton } from 'src/assets/icons/styled';

export default function Snackbar({ bottom }: { bottom: number }) {
  const [message, isShowSnackbarCloseButton, setMessage] = useLayoutStore((state) => [state.snackbarMessage, state.isShowSnackbarCloseButton, state.setSnackbarMessage], shallow);

  const handleCloseButtonClick = () => setMessage(null);

  useEffect(() => {
    setTimeout(() => setMessage(null), 2000);
  }, []);

  return (
    <SnackbarContainer bottom={bottom}>
      <SnackbarMessageText variant="letter/en, ko/small" color={'color/white'}>
        {message}
      </SnackbarMessageText>
      {isShowSnackbarCloseButton && (
        <IconButton onClick={handleCloseButtonClick}>
          <CloseIcon size={20} color={'white'} />
        </IconButton>
      )}
    </SnackbarContainer>
  );
}

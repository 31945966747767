import React, { ReactNode } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useSettings from '@hooks/useSettings';
import { PAGE_TYPE } from 'src/constants';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// [todo]: ThemeMui로 변경할 예정, 변경 완료 후 삭제해야 함.
const toonTheme: DefaultTheme = {
  colors: {
    icon: '#222222',
    activeText: '#222222',
    text: '#7c7c7c',
    gnbBg: '#ffffff',
    gnbCount: '#4E4EFF',
    gnbCountText: '#ffffff',
    listBorder: '#efefef',
    colBg: '#ffffff',
    colActiveBg: '#f8f8f8',
    colText: '#999999',
    colActiveText: '#222222',
    gnbDivider: '#e7e7e8',
    searchBg: '#F5F5F6',
    searchBorder: '#E7E7E8',
    searchText: '#7c7c7c',
    desktopSearchIcon: '#222222',
    cardGroupDivider: '#eeeeee',
  },
};

// [todo]: ThemeMui로 변경할 예정, 변경 완료 후 삭제해야 함.
const gameTheme: DefaultTheme = {
  colors: {
    icon: '#ffffff',
    activeText: '#ffffff',
    text: '#6d6d70',
    gnbBg: '#18181b',
    gnbCount: '#33ee99',
    gnbCountText: '#000000',
    listBorder: '#2b2b2b',
    colBg: '#383838',
    colActiveBg: '#121212',
    colText: 'rgba(255, 255, 255, 0.4)',
    colActiveText: '#ffffff',
    gnbDivider: '#d3d3d3',
    searchBg: '#313135',
    searchBorder: '#18181b',
    searchText: '#88888A',
    desktopSearchIcon: '#d2d2d3',
    cardGroupDivider: '#ffffff',
  },
};

// [todo]: ThemeMui로 변경할 예정, 변경 완료 후 삭제해야 함.
const themes = {
  [PAGE_TYPE.TOON]: toonTheme,
  [PAGE_TYPE.GAME]: gameTheme,
};

export default function ThemeIndustry({ children }: Props) {
  const { themeMode } = useSettings();
  const themeMui = useTheme();
  const theme = themes[themeMode] ? themes[themeMode] : themes.toon;

  // [todo]: 최종적으로 themeMui만 사용하도록 변경해야 함.
  return <ThemeProvider theme={{ ...themeMui, ...theme }}>{children}</ThemeProvider>;
}

import { create } from 'zustand';
import { ILayoutStatus } from './useLayout.type';

export const useLayoutStore = create<ILayoutStatus>()((set) => ({
  fixedButtonsBottom: null,
  setFixedButtonsBottom: (bottom: number) => set(() => ({ fixedButtonsBottom: bottom })),
  isShowTopButton: false,
  setIsShowTopButton: (isShowTopButton: boolean) => set(() => ({ isShowTopButton: isShowTopButton })),
  snackbarMessage: null,
  isShowSnackbarCloseButton: false,
  setSnackbarMessage: (snackbarMessage: string | null, isShowSnackbarCloseButton?: boolean) =>
    set(() => ({ snackbarMessage: snackbarMessage, isShowSnackbarCloseButton: isShowSnackbarCloseButton })),
}));
